import React from 'react';
import './shares.css';


const Shares = () => {


    

    return (

        <>
           
        </>
    );
}

export default Shares