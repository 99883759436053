import React, { useState, useEffect } from 'react'
import axios from 'axios';
import MaxPain from '../assets/images/Max Pain Final Poster.png';
import './events.css';
/*import QueenofJazz from '../assets/images/Final Poster September.jpeg';
import Extreme from '../assets/images/Piano House Extreme.jpeg';
import Queens from '../assets/images/Queens of Jazz.jpg'; */


const AttendEvent = () => {
    
    const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const   
 { data } = await axios.get('https://indie-userauth.onrender.com/api/events');
        setEvents(data);
      } catch (error) {
        console.error('Error fetching data: ', error);
        setError('Failed to load events. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);


    return (
        <>
        <div className= "attendevent">
        <div className = "attend-container">

           {/*} <div className = "attend-card">
                <div className = "attend-card-row">
                    <img src= {QueenofJazz} alt="Event A" />
                </div>
                <div className = "attend-card-text">
                    <h5> Queens of Jazz: A Brass Affair </h5>
                    <h6>Date: 14th September 2024 <br/>
                    Venue: The Code Nairobi <br/>
                    Time: 2pm-6pm
                    </h6>
                    <p> <a href= "https://apps.little.africa/events/queens-of-jazz-a-brass-affair-3">Click here </a> to buy tickets </p>
                    
                   {/* <div className="attend-card-button">
                            <a href="/tickets">Buy Ticket </a>
                          </div> 
                </div>
            </div>
            
            
            <div className = "attend-card">
            <div className = "attend-card-row">
                    <img src= {MaxPain} alt="Event B" />
                </div>
                <div className = "attend-card-text">
                    <h5> Max Pain & Friends: Stand-Up Comedy </h5>
                    <h6>Date: 14th September 2024 <br/>
                    Venue: Ayira Neurosoul Cafe <br/>
                    Time: 6pm
                    </h6>
                    <p> <a href= "https://triply.co/events/details?id=66adb93a55f73ba01afea793">Click here </a> to buy tickets </p>
                   {/* <div className="attend-card-button">
                            <a href="/tickets">Buy Ticket </a>
                        </div> 
                </div>
            </div>
           
           
            <div className = "attend-card">
            <div className = "attend-card-row">
                    <img src= {Extreme} alt="Event C" />
                </div>
                <div className = "attend-card-text">
                    <h5> Piano House Extreme </h5>
                    <h6>Date: 18th October 2024 <br/>  
                        Venue: Shelter, 33 Woodvale Grove
                    </h6>
                    <p>Details</p>
                  {/*  <div className="attend-card-button">
                            <a href="/tickets">Buy Ticket </a>
                    </div> 
                </div>
            </div>
            
            
            
            <div className = "attend-card">
            <div className = "attend-card-row">
                    <img src= {Queens} alt="Event D" />
                </div>
                <div className = "attend-card-text">
                    <h5> Queens of Jazz <br/>
                    <p>A night of Soul & Storytelling from leading ladies in the Nairobi Jazz scene.</p>
                    </h5>
                    <h6>Date: 21st June 2024 <br/>
                    Venue: Big Smoke (Karen) <br/>
                    Time: 7pm
                    </h6>
                    <p> <a href= "https://apps.little.africa/events/queens-of-jazz--2">Click here </a> to buy tickets </p>
                   {/* <div className="attend-card-button">
                            <a href="/tickets">Buy Ticket </a>
                </div> 
                </div>
            </div>
            
            
           {/* <div className = "attend-card">
            <div className = "attend-card-row">
                    <img src= {Lust} alt="Event E" />
                </div>
                <div className = "attend-card-text">
                    <h5> Event E </h5>
                    <h6>Date</h6>
                    <p>Details</p>
                   {/* <div className="attend-card-button">
                            <a href="/tickets">Buy Ticket </a>
            </div> 
                </div>
            </div> */}



        {isLoading && <p>Loading events...</p>}
        {error && <p className="eventserror-message">{error}</p>}
        {events.map(event => (
          <div className="attend-card" key={event.id}>
            <div className="attend-card-row">
              <img src={event.image || {MaxPain}} alt={event.name} />
            </div>
            <div className="attend-card-text">
              <h5>{event.name} <br/>
              <p>{event.desc}</p>
              </h5>
              <h6>
                Date: {event.date} <br />
                Venue: {event.venue} <br />
                Time: {event.time}
              </h6>
              <p>
                <a href={event.ticketLink}>Click here </a> to buy tickets
              </p>
            </div>
          </div>
        ))}
      </div>

        </div>

        


        </>
    );
}
export default AttendEvent;