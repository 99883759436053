import React, { useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import './members.css';
import event from '../assets/images/Indieart.jpeg';
import EventA from '../assets/images/screenshot-2024-05-13-at-06.06.03-715x714.webp';
import EventB from '../assets/images/queens-of-jazz-2020-785x785.webp';
import EventC from '../assets/images/screenshot-2024-05-13-at-06.13.11-600x601.webp';
import EventD from '../assets/images/mbr-600x400.webp';
import Nutshell from '../assets/images/Screenshot 2024-09-02 075243.png';

const AboutArtClub = () => {


    const slides = [
        {
            src: EventA,
            alt: 'Event A',
            text: 'Event A',
            description: 'Manage Event'
        },
        {
            src: EventB,
            alt: 'Event B',
            text: 'Event B',
            description: 'Manage Event'
        },
        {
            src: EventC,
            alt: 'Event C',
            text: 'Event C',
            description: 'Manage Event'
        },
        {
        src: EventD,
        alt: 'Event D',
        text: 'Event D',
        description: 'Manage Event'
    }
    ];

    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, containScroll: 'keepSnaps' }, [Autoplay(3000)])


    const scrollPrev = useCallback(() => {
        if (emblaApi) emblaApi.scrollPrev();
    }, [emblaApi]);
    
    const scrollNext = useCallback(() => {
        if (emblaApi) emblaApi.scrollNext();
    }, [emblaApi]);


    return (
        <>

        <section className="header-section">
                <div className="row">
                    
                    <div className="textwrapper">
                        <h1 className="title">Welcome, Member!</h1>
                        <p className="description">
                            - Earn profits from your events, <br />
                            - Manage your Art Club Portfolio <br />
                            - Get Artist-specific perks and access to a community of fellow Art lovers.<br />
                        </p>
                        <div className="button-group">
                            <a className="explore-btn" href="/events">Explore all Events</a>
                            <a className="performance-btn" href="/attend">Performance</a>
                        </div>
                    </div>
                    <div className="imagewrapper">
                        <img src={event} alt="event" />
                    </div>
                </div>
            
        </section>


        <section className="fund">
        <div className="art-container">
        <div className="art-col2">
                    <h5 className="section-title">Privately Fund Art You Love</h5>
                    <h6 className="section-subtitle">Artists Pitch, You Join the Club, Artists Host their Event, Everyone Shares<br />&nbsp;</h6>
                </div>
           
                <div className="art-col">
                <img src={Nutshell} alt="INDIE" />
                </div>
                 
            </div>
    

            </section>


           {/* <section className = "events-portfolio">

            <div className="portfolio-container">

            <div className = "portfolio-text"> 
            <h4>My Events Portfolio</h4>
            <h5>Click on the "Manage Event" button to view the event and manage it. You can view ticket sales, buy more shares, or transfer your shares.</h5><br /><br />
            </div>
            
            <div className="slider-container2">
            {slides.map((slide, index) => (
                <div
                    key={index}
                    className={`slide2 ${index === currentSlide ? 'active' : ''}`}>
                    <div className="slide-image" style={{ backgroundImage: `url(${slide.src})` }}> </div>
                
                    <div className="slide-content">
                        <h2>{slide.text}</h2>
                        <button onClick={() => handleButtonClick()}>Manage Event</button>
                    </div>
                </div>
            ))}
            <button className="control prev" onClick={handlePrev}>❮</button>
            <button className="control next" onClick={handleNext}>❯</button>
        </div>
            

</div>

        
            </section> */}




<section className= "events-portfolio">
<div className="portfolio-container">

    <div className = "portfolio-text"> 
            <h4>My Events Portfolio</h4>
            <h5>Click on the "Manage Event" button to view the event and manage it. You can view ticket sales, buy more shares, or transfer your shares.</h5><br /><br />
            </div>
  <div className="slider-container2">

    <div className="embla4" ref={emblaRef}>
                <div className="embla__viewport4">
                {slides.map((slide, index) => (
    <div key={index} className="embla__slide4 portfolio-card">  {/* If slide.id is always unique, use it instead of index */}
        <div className="event-card__slide-image">
            <img src={slide.src} alt={slide.alt} />  {/* Ensuring alt text is descriptive */}
                     </div>
                          <div className="slide-content">
                          <h2>{slide.text}</h2>
                        <a className="manage-button" href="/events">Manage Event</a>
                      </div>
                      </div>
                  ))}
                  
                  </div>
                  </div>
                  <button className="carousel-button-prev" onClick={scrollPrev} aria-label="Previous slide">
                  ❮
                  </button>
                  <button className="carousel-button-next" onClick={scrollNext} aria-label="Next slide">
                  ❯
                  </button>
                 
                  </div>
                  
                  </div>
                </section> 



        </>
    );
}
export default AboutArtClub;