import React, { useState } from 'react';
import './passwordreset.css';
import axios from 'axios';

const PasswordReset = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(''); // Used for both error and success messages

    const handleReset = async () => {
        if (!email) {
            setMessage('Email address is required');
            return;
        }

        try {
            const response = await axios.post('https://indie-userauth.onrender.com/resetpassword', { email });
            if (response.status === 200) {
                // Assuming the backend sends back a success message or other data
                const serverMessage = response.data.message; // Adjust 'message' to the actual data field name
                setMessage(serverMessage || `Reset link sent to ${email}. Check your inbox!`);
            } else {
                // Handle other statuses or general success without specific message
                setMessage(`Reset link sent to ${email}`);
            }
            console.log('Reset link sent to:', email);
            // Clear the email input if needed
            setEmail('');
        } catch (error) {
            setMessage('Error sending reset link. Please try again later.');
            console.error('Error sending reset link:', error);
        }
    };

    return (
        <div className="password-reset-container">
            <div className="password-reset-row">
                <h1>Reset Your Password</h1>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    className="password-reset-input"
                />
                <div className="message">{message}</div> {/* Updated to show success message */}
                <button onClick={handleReset} className="password-reset-button">
                    Send Reset Link
                </button>
            </div>
        </div>
    );
};

export default PasswordReset;
